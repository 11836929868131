.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.estimatedTime{
  animation: fadeIn 2s; 
}

@keyframes fadeIn {
  0%{opacity: 0}
  100%{opacity: 1;}
}

.word{
  
  left: 0;
  right: 0;
  font-size: 1.2em;
  margin: auto;
  animation: switch 28s infinite;
}

.word:nth-child(1) {
  -webkit-animation-delay: 1s; 
    animation-delay: 1s;
}

.word:nth-child(2) {
 
  -webkit-animation-delay: 1s; 
  animation-delay: 10s;
}

.word:nth-child(3) {
  
  -webkit-animation-delay: 2s; 
    animation-delay: 20s;
}

@keyframes switch {
  0%{opacity: 0}
  10%{opacity: 1;}
  20%{opacity: 1}
  25%{opacity: 0}
  80%{opacity: 0}
  90%{opacity: 0;}
}



