* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto";
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.estimatedTime{
  -webkit-animation: fadeIn 2s;
          animation: fadeIn 2s; 
}

@-webkit-keyframes fadeIn {
  0%{opacity: 0}
  100%{opacity: 1;}
}

@keyframes fadeIn {
  0%{opacity: 0}
  100%{opacity: 1;}
}

.word{
  
  left: 0;
  right: 0;
  font-size: 1.2em;
  margin: auto;
  -webkit-animation: switch 28s infinite;
          animation: switch 28s infinite;
}

.word:nth-child(1) {
  -webkit-animation-delay: 1s; 
    animation-delay: 1s;
}

.word:nth-child(2) {
 
  -webkit-animation-delay: 1s; 
  animation-delay: 10s;
}

.word:nth-child(3) {
  
  -webkit-animation-delay: 2s; 
    animation-delay: 20s;
}

@-webkit-keyframes switch {
  0%{opacity: 0}
  10%{opacity: 1;}
  20%{opacity: 1}
  25%{opacity: 0}
  80%{opacity: 0}
  90%{opacity: 0;}
}

@keyframes switch {
  0%{opacity: 0}
  10%{opacity: 1;}
  20%{opacity: 1}
  25%{opacity: 0}
  80%{opacity: 0}
  90%{opacity: 0;}
}





*{
  font-family: 'Roboto';
}


.AlgemeneDelen{
  width: 20vw;
}

.Appartementen{
  width: 20vw
}

#arrowAnim {
  height: 60px;
  display: flex;
  -webkit-transform: rotate(225deg);
          transform: rotate(225deg);
}

#arrowNotAnim {
  height: 60px;
  display: flex;
  -webkit-transform: rotate(225deg);
          transform: rotate(225deg);
}




#arrowAnim3 {
  margin-top: 2em;
  display: flex;
  -webkit-transform: rotate(220deg);
          transform: rotate(220deg);
}

#arrowAnim4 {
  
  display: flex;
  -webkit-transform: rotate(325deg);
          transform: rotate(325deg);
}

#arrowAnim2 {
  margin-bottom: 2em;
  width: 20px;
  display: flex;
  -webkit-transform: rotate(320deg);
          transform: rotate(320deg);
}

#arrowAnim6 {
  margin-top: 100px;
  padding-right: 30px;
  display: flex;
  -webkit-transform: rotate(145deg);
          transform: rotate(145deg);
}

#arrowAnim7 {
  padding-top: 40px;
  display: flex;
  -webkit-transform: rotate(40deg);
          transform: rotate(40deg);
}

#arrowAnim8 {
  padding-top: 40px;
  display: flex;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.arrow {
  width: 1.3vw;
  height: 1.3vw;
  border: 4px solid;
  border-color: lightblue transparent transparent lightblue;
  -webkit-transform: rotate(-50deg);
          transform: rotate(-50deg);
}

.arrowNot {
  width: 1.3vw;
  height: 1.3vw;
  -webkit-transform: rotate(-50deg);
          transform: rotate(-50deg);
}


.arrow2 {
  width: 1.3vw;
  height: 1.3vw;
  border: 4px solid;
  border-color: red transparent transparent red;
  -webkit-transform: rotate(-50deg);
          transform: rotate(-50deg);
}


.arrowSliding {
  position: absolute;
  -webkit-animation: slide 4s linear infinite; 
          animation: slide 4s linear infinite;
}

.arrowSlidingMid {
  position: absolute;
  -webkit-animation: slideMid 8s linear infinite; 
          animation: slideMid 8s linear infinite;
}

.arrowSlidingNot {
  position: absolute;
  -webkit-animation: slideNot 6s linear infinite; 
          animation: slideNot 6s linear infinite;
}

.delay1 {
  -webkit-animation-delay: 1s; 
    animation-delay: 0.8s;
}
.delay2 {
  -webkit-animation-delay: 1s; 
    animation-delay: 1.6s;
}
.delay3 {
  -webkit-animation-delay: 1s; 
    animation-delay: 2.4s;
}
.delay4 {
  -webkit-animation-delay: 1s; 
    animation-delay: 3.2s;
}
.delay5 {
  -webkit-animation-delay: 1s; 
    animation-delay: 3.99999s;
}


.delay6 {
  -webkit-animation-delay: 1s; 
    animation-delay: 4.8s;
}
.delay7 {
  -webkit-animation-delay: 1s; 
    animation-delay: 5.6s;
}
.delay8 {
  -webkit-animation-delay: 1s; 
    animation-delay: 6.4s;
}
.delay9 {
  -webkit-animation-delay: 1s; 
    animation-delay: 7.2s;
}




.detail1 {
  color: white;
  -webkit-animation-name: example;
          animation-name: example;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
}
.detail2 {
  color: white;
  -webkit-animation-name: example;
          animation-name: example;
  -webkit-animation-duration: 6s;
          animation-duration: 6s;
}
.detail3 {
  color: white;
  -webkit-animation-name: example;
          animation-name: example;
  -webkit-animation-duration: 8s;
          animation-duration: 8s;
}


@-webkit-keyframes example {
  from {opacity: 0;}
  to {opacity: 1;}
}


@keyframes example {
  from {opacity: 0;}
  to {opacity: 1;}
}



@-webkit-keyframes slide {
  0% { opacity:0; -webkit-transform: translateX(150px); transform: translateX(150px); }  
   20% { opacity:1; -webkit-transform: translateX(60px); transform: translateX(60px); } 
   80% { opacity:1; -webkit-transform: translateX(-60px); transform: translateX(-60px); }  
  100% { opacity:0; -webkit-transform: translateX(-150px); transform: translateX(-150px); }  
}



@keyframes slide {
  0% { opacity:0; -webkit-transform: translateX(150px); transform: translateX(150px); }  
   20% { opacity:1; -webkit-transform: translateX(60px); transform: translateX(60px); } 
   80% { opacity:1; -webkit-transform: translateX(-60px); transform: translateX(-60px); }  
  100% { opacity:0; -webkit-transform: translateX(-150px); transform: translateX(-150px); }  
}


@-webkit-keyframes slideNot {
  0% { opacity:0; -webkit-transform: translateX(70px); transform: translateX(70px); }  
   20% { opacity:1; -webkit-transform: translateX(-0px); transform: translateX(-0px); } 
   80% { opacity:1; -webkit-transform: translateX(-140px); transform: translateX(-140px); }  
  100% { opacity:0; -webkit-transform: translateX(-220px); transform: translateX(-220px); }  
}


@keyframes slideNot {
  0% { opacity:0; -webkit-transform: translateX(70px); transform: translateX(70px); }  
   20% { opacity:1; -webkit-transform: translateX(-0px); transform: translateX(-0px); } 
   80% { opacity:1; -webkit-transform: translateX(-140px); transform: translateX(-140px); }  
  100% { opacity:0; -webkit-transform: translateX(-220px); transform: translateX(-220px); }  
}


@-webkit-keyframes slideMid {
  0% { opacity:0; -webkit-transform: translateX(350px); transform: translateX(350px); }  
   20% { opacity:1; -webkit-transform: translateX(250px); transform: translateX(250px); } 
   80% { opacity:1; -webkit-transform: translateX(-100px); transform: translateX(-100px); }  
  100% { opacity:0; -webkit-transform: translateX(-200px); transform: translateX(-200px); }  
}


@keyframes slideMid {
  0% { opacity:0; -webkit-transform: translateX(350px); transform: translateX(350px); }  
   20% { opacity:1; -webkit-transform: translateX(250px); transform: translateX(250px); } 
   80% { opacity:1; -webkit-transform: translateX(-100px); transform: translateX(-100px); }  
  100% { opacity:0; -webkit-transform: translateX(-200px); transform: translateX(-200px); }  
}


@media screen and (max-width: 1070px) {
  @-webkit-keyframes slideMid {
    0% { opacity:0; -webkit-transform: translateX(350px); transform: translateX(350px); }  
     20% { opacity:1; -webkit-transform: translateX(250px); transform: translateX(250px); } 
     80% { opacity:1; -webkit-transform: translateX(-50px); transform: translateX(-50px); }  
    100% { opacity:0; -webkit-transform: translateX(-150px); transform: translateX(-150px); }  
  }
  @keyframes slideMid {
    0% { opacity:0; -webkit-transform: translateX(350px); transform: translateX(350px); }  
     20% { opacity:1; -webkit-transform: translateX(250px); transform: translateX(250px); } 
     80% { opacity:1; -webkit-transform: translateX(-50px); transform: translateX(-50px); }  
    100% { opacity:0; -webkit-transform: translateX(-150px); transform: translateX(-150px); }  
  }
}

@media screen and (max-width: 1250px) {
  .arrow {
    width: 1.7vw;
    height: 1.7vw;
    
  }
  .arrow2 {
    width: 1.7vw;
    height: 1.7vw;
    
  }
  
}




@media screen and (max-width: 900px) {
  .arrowNot {
    -webkit-transform: rotate(-50deg);
            transform: rotate(-50deg);
  }

  .Appartementen{

    width:100%; 
    
  }
  .AlgemeneDelen{
    width:30vw;
  }

  
  
  .arrow {
    width: 2.2vw;
    height: 2.2vw;
  }
  .arrow2 {
    width: 2.2vw;
    height: 2.2vw;
  }

  #arrowAnim {
    padding-right: 20px;
    
    display: flex;
    -webkit-transform: rotate(225deg);
            transform: rotate(225deg);
  }
  
  #arrowNotAnim {
    height: 60px;
    display: flex;
    -webkit-transform: rotate(225deg);
            transform: rotate(225deg);
  }
  
  
  
  #arrowAnim3 {
  
    display: flex;
    -webkit-transform: rotate(235deg);
            transform: rotate(235deg);
  }
  
  #arrowAnim4 {
    
    
    display: flex;
    -webkit-transform: rotate(315deg);
            transform: rotate(315deg);
  }
  
  #arrowAnim2 {
    display: flex;
    -webkit-transform: rotate(320deg);
            transform: rotate(320deg);
  }
  
  #arrowAnim6 {
    margin-top: 80px;
    
    display: flex;
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
  }
  
  #arrowAnim7 {
    padding-top: 40px;
    display: flex;
    -webkit-transform: rotate(50deg);
            transform: rotate(50deg);
  }
  
  #arrowAnim8 {
    padding-top: 40px;
    display: flex;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  
  .Pop6{
    background-color: white;
    border: 0px;
    display: none;

}

.PopTxt{
  display: none;
}

#BottomCard{
  display: grid;
}

  #popup7{
    display: block;
  }
  #popup8{
    display: block;
  }
  #popup9{
    display: block;
  }
  #popup10{
    display: block;
  }
  #popup11{
    display: block;
  }
  


  #popup2{
    width: 10%;
    height: 10%;
    background-color: white;
    border: 0px;
  > *{
    width: 20%;
    height: 20%;
    display: none;
  }
}
  #popup1{
    width: 10%;
    height: 10%;
    background-color: white;
    border: 0px;
 
  > *{
    width: 20%;
    height: 20%;
    display: none;
  }
}



 
  @-webkit-keyframes slide {
    0% { opacity:0; -webkit-transform: translateX(62px); transform: translateX(62px); }  
     20% { opacity:1; -webkit-transform: translateX(30px); transform: translateX(30px); } 
     80% { opacity:1; -webkit-transform: translateX(-60px); transform: translateX(-60px); }  
    100% { opacity:0; -webkit-transform: translateX(-90px); transform: translateX(-90px); }  
  }



 
  @keyframes slide {
    0% { opacity:0; -webkit-transform: translateX(62px); transform: translateX(62px); }  
     20% { opacity:1; -webkit-transform: translateX(30px); transform: translateX(30px); } 
     80% { opacity:1; -webkit-transform: translateX(-60px); transform: translateX(-60px); }  
    100% { opacity:0; -webkit-transform: translateX(-90px); transform: translateX(-90px); }  
  }
  
  @-webkit-keyframes slideNot {
    0% { opacity:0; -webkit-transform: translateX(40px); transform: translateX(40px); }  
     20% { opacity:1; -webkit-transform: translateX(0px); transform: translateX(0px); } 
     80% { opacity:1; -webkit-transform: translateX(-120px); transform: translateX(-120px); }  
    100% { opacity:0; -webkit-transform: translateX(-180px); transform: translateX(-180px); }  
  }
  
  @keyframes slideNot {
    0% { opacity:0; -webkit-transform: translateX(40px); transform: translateX(40px); }  
     20% { opacity:1; -webkit-transform: translateX(0px); transform: translateX(0px); } 
     80% { opacity:1; -webkit-transform: translateX(-120px); transform: translateX(-120px); }  
    100% { opacity:0; -webkit-transform: translateX(-180px); transform: translateX(-180px); }  
  }
 
  @-webkit-keyframes slideMid {
    0% { opacity:0; -webkit-transform: translateX(290px); transform: translateX(290px); }  
     20% { opacity:1; -webkit-transform: translateX(200px); transform: translateX(200px); } 
     80% { opacity:1; -webkit-transform: translateX(-60px); transform: translateX(-60px); }  
    100% { opacity:0; -webkit-transform: translateX(-120px); transform: translateX(-120px); }  
  }
 
  @keyframes slideMid {
    0% { opacity:0; -webkit-transform: translateX(290px); transform: translateX(290px); }  
     20% { opacity:1; -webkit-transform: translateX(200px); transform: translateX(200px); } 
     80% { opacity:1; -webkit-transform: translateX(-60px); transform: translateX(-60px); }  
    100% { opacity:0; -webkit-transform: translateX(-120px); transform: translateX(-120px); }  
  }
  
}

@media screen and (max-width: 740px) {
  .arrow {
    width: 2.5vw;
    height: 2.5vw;
    border: 3px solid;
    border-color: lightblue transparent transparent lightblue;
    -webkit-transform: rotate(-50deg);
            transform: rotate(-50deg);

  }

  .arrow2 {
    width: 2.5vw;
    height: 2.5vw;
    border: 3px solid;
    border-color: red transparent transparent red;
  -webkit-transform: rotate(-50deg);
          transform: rotate(-50deg);

  }

  #arrowAnim2 {
    margin-top: 70px;
    display: flex;
    -webkit-transform: rotate(320deg);
            transform: rotate(320deg);
  }
  .AlgemeneDelen{
    width: calc(50% + 5px); /* Adjust width and subtract 2px for the gap */
    margin-left: 2px; /* Add 2px gap on the right side */
    padding-top: 20px;
    position:relative;
    white-space: nowrap;
  }


  @-webkit-keyframes slideMid {
    0% { opacity:0; -webkit-transform: translateX(200px); transform: translateX(200px); }  
     20% { opacity:1; -webkit-transform: translateX(140px); transform: translateX(140px); } 
     80% { opacity:1; -webkit-transform: translateX(-0px); transform: translateX(-0px); }  
    100% { opacity:0; -webkit-transform: translateX(-50px); transform: translateX(-50px); }  
  }


  @keyframes slideMid {
    0% { opacity:0; -webkit-transform: translateX(200px); transform: translateX(200px); }  
     20% { opacity:1; -webkit-transform: translateX(140px); transform: translateX(140px); } 
     80% { opacity:1; -webkit-transform: translateX(-0px); transform: translateX(-0px); }  
    100% { opacity:0; -webkit-transform: translateX(-50px); transform: translateX(-50px); }  
  }
  
  @-webkit-keyframes slideNot {
    0% { opacity:0; -webkit-transform: translateX(0px); transform: translateX(0px); }  
     20% { opacity:1; -webkit-transform: translateX(-40px); transform: translateX(-40px); } 
     80% { opacity:1; -webkit-transform: translateX(-120px); transform: translateX(-120px); }  
    100% { opacity:0; -webkit-transform: translateX(-180px); transform: translateX(-180px); }  
  }
  
  @keyframes slideNot {
    0% { opacity:0; -webkit-transform: translateX(0px); transform: translateX(0px); }  
     20% { opacity:1; -webkit-transform: translateX(-40px); transform: translateX(-40px); } 
     80% { opacity:1; -webkit-transform: translateX(-120px); transform: translateX(-120px); }  
    100% { opacity:0; -webkit-transform: translateX(-180px); transform: translateX(-180px); }  
  }

  @-webkit-keyframes slide {
    0% { opacity:0; -webkit-transform: translateX(62px); transform: translateX(62px); }  
     20% { opacity:1; -webkit-transform: translateX(30px); transform: translateX(30px); } 
     80% { opacity:1; -webkit-transform: translateX(-40px); transform: translateX(-40px); }  
    100% { opacity:0; -webkit-transform: translateX(-70px); transform: translateX(-70px); }  
  }

  @keyframes slide {
    0% { opacity:0; -webkit-transform: translateX(62px); transform: translateX(62px); }  
     20% { opacity:1; -webkit-transform: translateX(30px); transform: translateX(30px); } 
     80% { opacity:1; -webkit-transform: translateX(-40px); transform: translateX(-40px); }  
    100% { opacity:0; -webkit-transform: translateX(-70px); transform: translateX(-70px); }  
  }
}


@media screen and (max-width: 500px) {
  
  .Appartementen{
    grid-template-columns: repeat(2, 1fr);
    padding-top: 20px;
    
    position:relative;
    white-space: nowrap;
    
    position: relative;
  }
  .appBox{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: calc(50% - 5px); /* Adjust width and subtract 2px for the gap */
    height: 100%; /* Adjust height as needed */
    margin-right: 2px; /* Add 2px gap on the right side */

  }

  .AlgemeneDelen{
    width: calc(50% + 5px); /* Adjust width and subtract 2px for the gap */
    margin-left: 2px; /* Add 2px gap on the right side */
    padding-top: 20px;
    position:relative;
    white-space: nowrap;
  }

  .ADBox{
    width: calc(50% + 5px); /* Adjust width and subtract 2px for the gap */
  }


  #arrowAnim {
    padding-right: 20px;
    
    display: flex;
    -webkit-transform: rotate(245deg);
            transform: rotate(245deg);
  }
  
  #arrowNotAnim {
    height: 60px;
    display: flex;
    -webkit-transform: rotate(225deg);
            transform: rotate(225deg);
  }
  
  
  
  #arrowAnim3 {
  
    display: flex;
    -webkit-transform: rotate(240deg);
            transform: rotate(240deg);
  }
  
  #arrowAnim4 {
    padding-right: 2em;
    
    display: flex;
    -webkit-transform: rotate(305deg);
            transform: rotate(305deg);
  }
  
  #arrowAnim2 {
    display: flex;
    -webkit-transform: rotate(290deg);
            transform: rotate(290deg);
    margin-top: 80px
  }
  
  #arrowAnim6 {
    
    display: flex;
    -webkit-transform: rotate(120deg);
            transform: rotate(120deg);
  }
  
  #arrowAnim7 {
    
    display: flex;
    -webkit-transform: rotate(65deg);
            transform: rotate(65deg);
  }
  #arrowAnim8 {
    margin-right: 30px;
  }

  .arrow {
    width: 15px;
    height: 15px;
    border: 3px solid;
    border-color: lightblue transparent transparent lightblue;
    -webkit-transform: rotate(-50deg);
            transform: rotate(-50deg);
  }

  .arrow2 {
    width: 15px;
    height: 15px;
    border: 3px solid;
    border-color: red transparent transparent red;
    -webkit-transform: rotate(-50deg);
            transform: rotate(-50deg);
  }
  .containersIcons{
    width:"30vw"
  }
  

  @-webkit-keyframes slideMid {
    0% { opacity:0; -webkit-transform: translateX(100px); transform: translateX(100px); }  
     20% { opacity:1; -webkit-transform: translateX(60px); transform: translateX(60px); } 
     80% { opacity:1; -webkit-transform: translateX(-10px); transform: translateX(-10px); }  
    100% { opacity:0; -webkit-transform: translateX(-70px); transform: translateX(-70px); }  
  }
  

  @keyframes slideMid {
    0% { opacity:0; -webkit-transform: translateX(100px); transform: translateX(100px); }  
     20% { opacity:1; -webkit-transform: translateX(60px); transform: translateX(60px); } 
     80% { opacity:1; -webkit-transform: translateX(-10px); transform: translateX(-10px); }  
    100% { opacity:0; -webkit-transform: translateX(-70px); transform: translateX(-70px); }  
  }
  @-webkit-keyframes slideNot {
    0% { opacity:0; -webkit-transform: translateX(0px); transform: translateX(0px); }  
     20% { opacity:1; -webkit-transform: translateX(-40px); transform: translateX(-40px); } 
     80% { opacity:1; -webkit-transform: translateX(-120px); transform: translateX(-120px); }  
    100% { opacity:0; -webkit-transform: translateX(-180px); transform: translateX(-180px); }  
  }
  @keyframes slideNot {
    0% { opacity:0; -webkit-transform: translateX(0px); transform: translateX(0px); }  
     20% { opacity:1; -webkit-transform: translateX(-40px); transform: translateX(-40px); } 
     80% { opacity:1; -webkit-transform: translateX(-120px); transform: translateX(-120px); }  
    100% { opacity:0; -webkit-transform: translateX(-180px); transform: translateX(-180px); }  
  }

  @-webkit-keyframes slide {
    0% { opacity:0; -webkit-transform: translateX(40px); transform: translateX(40px); }  
     20% { opacity:1; -webkit-transform: translateX(30px); transform: translateX(30px); } 
     80% { opacity:1; -webkit-transform: translateX(-40px); transform: translateX(-40px); }  
    100% { opacity:0; -webkit-transform: translateX(-90px); transform: translateX(-90px); }  
  }

  @keyframes slide {
    0% { opacity:0; -webkit-transform: translateX(40px); transform: translateX(40px); }  
     20% { opacity:1; -webkit-transform: translateX(30px); transform: translateX(30px); } 
     80% { opacity:1; -webkit-transform: translateX(-40px); transform: translateX(-40px); }  
    100% { opacity:0; -webkit-transform: translateX(-90px); transform: translateX(-90px); }  
  }
}

